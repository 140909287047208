import React, { useRef, useEffect } from 'react';
import {
  BarChart, Bar, LineChart, Line, Cell, ScatterChart, Scatter,
  PieChart, Pie, XAxis, YAxis, CartesianGrid, Tooltip,
  Legend, ResponsiveContainer
} from 'recharts';
import {
  Button, FormControl, InputLabel, Select, MenuItem,
  Box, Typography, Alert
} from '@mui/material';
import { toPng } from 'html-to-image';

const Chart = ({
  data,
  chartType,
  xAxis,
  yAxis,
  availableColumns,
  onChartTypeChange,
  onXAxisChange,
  onYAxisChange,
  title
}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    // Validate data when component mounts or data changes
    if (data && data.length > 0) {
      validateChartData();
    }
  }, [data]);


  const validateChartData = () => {
    // Check if data contains the specified axes
    if (!data[0].hasOwnProperty(xAxis) || !data[0].hasOwnProperty(yAxis)) {
      console.warn('Data does not contain specified axes:', { xAxis, yAxis });
      return false;
    }
    return true;
  };

  const copyChartToClipboard = async () => {
    if (chartRef.current) {
      try {
        const dataUrl = await toPng(chartRef.current);
        const link = document.createElement('a');
        link.download = `chart-${new Date().toISOString()}.png`;
        link.href = dataUrl;
        link.click();
      } catch (err) {
        console.error('Помилка при копіюванні діаграми:', err);
      }
    }
  };

  const renderChart = () => {
    if (!data || data.length === 0) {
      return <Alert severity="warning">Немає даних для відображення</Alert>;
    }

    try {
      switch (chartType) {
        case 'bar':
          return (
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey={xAxis}
                angle={-45}
                textAnchor="end"
                height={70}
              />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey={yAxis}
                fill="#8884d8"
                animationDuration={1000}
                name={yAxis}
              />
            </BarChart>
          );
        case 'line':
          return (
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey={xAxis}
                angle={-45}
                textAnchor="end"
                height={70}
              />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey={yAxis}
                stroke="#8884d8"
                animationDuration={1000}
                name={yAxis}
              />
            </LineChart>
          );
        case 'scatter':
          return (
            <ScatterChart>
              <CartesianGrid />
              <XAxis
                dataKey={xAxis}
                type="number"
                name={xAxis}
              />
              <YAxis
                dataKey={yAxis}
                type="number"
                name={yAxis}
              />
              <Tooltip cursor={{ strokeDasharray: '3 3' }} />
              <Legend />
              <Scatter
                name={`${xAxis} vs ${yAxis}`}
                data={data}
                fill="#8884d8"
                animationDuration={1000}
              />
            </ScatterChart>
          );
        case 'pie':
          return (
            <PieChart>
              <Pie
                dataKey={yAxis}
                nameKey={xAxis}
                data={data}
                fill="#8884d8"
                label
                animationDuration={1000}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          );
        default:
          return <Alert severity="error">Непідтримуваний тип діаграми: {chartType}</Alert>;
      }
    } catch (error) {
      console.error('Error rendering chart:', error);
      return <Alert severity="error">Помилка відображення діаграми: {error.message}</Alert>;
    }
  };

  return (
    <Box ref={chartRef} sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
      {title && (
        <Typography variant="h6" gutterBottom align="center">
          {title}
        </Typography>
      )}

      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <FormControl fullWidth>
          <InputLabel>Тип діаграми</InputLabel>
          <Select
            value={chartType}
            onChange={(e) => onChartTypeChange(e.target.value)}
            size="small"
          >
            <MenuItem value="bar">Гістограма</MenuItem>
            <MenuItem value="line">Лінійна</MenuItem>
            <MenuItem value="scatter">Розсіювання</MenuItem>
            <MenuItem value="pie">Кільцева</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Вісь X</InputLabel>
          <Select
            value={xAxis}
            onChange={(e) => onXAxisChange(e.target.value)}
            size="small"
          >
            {availableColumns.map((column) => (
              <MenuItem key={column} value={column}>{column}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>Вісь Y</InputLabel>
          <Select
            value={yAxis}
            onChange={(e) => onYAxisChange(e.target.value)}
            size="small"
          >
            {availableColumns.map((column) => (
              <MenuItem key={column} value={column}>{column}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ height: 400, width: '100%' }}>
        <ResponsiveContainer>
          {renderChart()}
        </ResponsiveContainer>
      </Box>

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={copyChartToClipboard}
          variant="outlined"
          size="small"
        >
          Зберегти діаграму
        </Button>
      </Box>
    </Box>
  );
};

// Color palette for pie chart segments
const COLORS = [
  '#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#0088fe',
  '#00c49f', '#ffbb28', '#ff8042', '#8884d8', '#82ca9d'
];

Chart.defaultProps = {
  data: [],
  availableColumns: [],
  title: '',
  chartType: 'bar'
};

export default Chart;