// components/DocumentViewer.js
import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography, Alert } from '@mui/material';

const DocumentViewer = ({ fileUrl, fileType, onLoad, onError }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getViewerUrl = () => {
    const encodedUrl = encodeURIComponent(fileUrl);

    // For PDF files use PDF.js viewer
    if (fileType === 'pdf') {
      return `https://mozilla.github.io/pdf.js/web/viewer.html?file=${encodedUrl}`;
    }

    // For Office documents, try Microsoft Office Online Viewer
    if (['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].includes(fileType)) {
      return `https://view.officeapps.live.com/op/embed.aspx?src=${encodedUrl}`;
    }

    // For other files, use Google Docs viewer as last resort
    return `https://docs.google.com/gview?url=${encodedUrl}&embedded=true`;
  };

  const handleLoad = () => {
    setLoading(false);
    if (onLoad) onLoad();
  };

  const handleError = (err) => {
    console.error('Viewer error:', err);
    setError('Помилка завантаження документу');
    setLoading(false);
    if (onError) onError(err);
  };

  return (
    <Box sx={{
      width: '100%',
      height: '800px',
      position: 'relative',
      bgcolor: '#f5f5f5',
      borderRadius: 1,
      overflow: 'hidden'
    }}>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            zIndex: 2,
          }}
        >
          <CircularProgress />
          <Typography sx={{ mt: 2 }}>Завантаження документу...</Typography>
        </Box>
      )}

      {error && (
        <Alert
          severity="error"
          sx={{
            position: 'absolute',
            top: 16,
            left: 16,
            right: 16,
            zIndex: 3
          }}
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      )}

      <iframe
        src={getViewerUrl()}
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
        }}
        onLoad={handleLoad}
        onError={handleError}
        title="Document Preview"
        allow="fullscreen"
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
      />
    </Box>
  );
};

export default DocumentViewer;