import React, { useState, useCallback, useMemo } from 'react';
import { Box, Tooltip, Snackbar } from '@mui/material';
import { format, parseISO } from 'date-fns';

function DigitalStamp({ signatureInfo }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const copyAllInfo = useCallback(() => {
    if (signatureInfo && signatureInfo.is_signed) {
      const formattedDate = signatureInfo.signature_date
        ? format(parseISO(signatureInfo.signature_date), "dd.MM.yyyy HH:mm")
        : "Дата не вказана";
      const textToCopy = `Ім'я: ${signatureInfo.signer_name}\nРНОКПП: ${signatureInfo.signer_tax_number}\nДата: ${formattedDate}`;
      navigator.clipboard.writeText(textToCopy).then(() => {
        setSnackbarMessage('Інформацію про підпис скопійовано');
        setSnackbarOpen(true);
      }, (err) => {
        console.error('Помилка копіювання: ', err);
        setSnackbarMessage('Помилка при копіюванні');
        setSnackbarOpen(true);
      });
    }
  }, [signatureInfo]);

  const toggleExpand = useCallback((e) => {
    e.stopPropagation(); // Prevent copyAllInfo from being called
    setIsExpanded(prev => !prev);
  }, []);

  const formatName = useCallback((name) => {
    if (!name) return ['Ім\'я', 'не', 'вказано'];
    return name.split(' ');
  }, []);

  const StampContent = useMemo(() => {
    if (!signatureInfo || !signatureInfo.is_signed) return null;

    const formattedDate = signatureInfo.signature_date
      ? format(parseISO(signatureInfo.signature_date), "dd.MM.yyyy HH:mm")
      : "Дата не вказана";

    const signatureMethod = signatureInfo.signature_method?.toLowerCase() === 'ecp'
      ? 'ЕЦП'
      : signatureInfo.signature_method || 'Метод не вказаний';

    return (
      <svg viewBox="0 0 200 200" width="200" height="200">
        <defs>
          <path id="stamp-circle-outer" d="M100,5 a95,95 0 0,1 0,190 a95,95 0 0,1 0,-190" />
          <path id="stamp-circle-inner" d="M100,15 a85,85 0 0,1 0,170 a85,85 0 0,1 0,-170" />
        </defs>
        <circle cx="100" cy="100" r="95" fill="#EEEEFF" fillOpacity="0.9" stroke="#000080" strokeWidth="2" />
        <text fontSize="8" fill="#000080">
          <textPath href="#stamp-circle-inner" startOffset="0%">
            LITOPYS • LITOPYS • LITOPYS • LITOPYS • LITOPYS • LITOPYS • LITOPYS • LITOPYS • LITOPYS • LITOPYS • LITOPYS • LITOPYS • LITOPYS • LITOPYS • LITOPYS • LITOPYS •
          </textPath>
        </text>
        {formatName(signatureInfo.signer_name).map((namePart, index) => (
          <text
            key={index}
            x="100"
            y={60 + index * 15}
            fontSize="12"
            fontWeight="bold"
            textAnchor="middle"
            fill="#000080"
          >
            {namePart}
          </text>
        ))}
        <text x="100" y="120" fontSize="12" textAnchor="middle" fontSize="12"
            fontWeight="bold" fill="#000080">
          {signatureInfo.signer_tax_number || 'РНОКПП не вказано'}
        </text>
        <text x="100" y="140" fontSize="10" textAnchor="middle" fill="#000080">
          {formattedDate}
        </text>
        <text x="100" y="160" fontSize="12" textAnchor="middle" fill="#000080">{signatureMethod}</text>
      </svg>
    );
  }, [signatureInfo, formatName]);

  if (!signatureInfo || !signatureInfo.is_signed) return null;

  return (
    <>
      <Tooltip title={isExpanded ? "Натисніть щоб згорнути" : "Натисніть щоб розгорнути"} arrow placement="left">
        <Box
          position="absolute"
          bottom={20}
          right={20}
          sx={{
            cursor: 'pointer',
            transform: isExpanded ? 'scale(1)' : 'scale(0.3)',
            transition: 'transform 0.3s ease-in-out',
            transformOrigin: 'bottom right'
          }}
          onClick={copyAllInfo}
        >
          <Box
            onClick={toggleExpand}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 1,
            }}
          />
          {StampContent}
        </Box>
      </Tooltip>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </>
  );
}

export default DigitalStamp;