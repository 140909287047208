// CustomizationPanel.js
import React, { useState } from 'react';
import { Paper, Typography, Slider, Button, Box } from '@mui/material';
import { SketchPicker } from 'react-color';

const CustomizationPanel = ({ onCustomizationChange }) => {
  const [color, setColor] = useState('#8884d8');
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
    onCustomizationChange({ color: newColor.hex });
  };

  const handleFontSizeChange = (event, newValue) => {
    onCustomizationChange({ fontSize: newValue });
  };

  return (
    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
      <Typography variant="h6">Налаштувати діаграму</Typography>
      <Box mt={2}>
        <Button
          variant="outlined"
          onClick={() => setShowColorPicker(!showColorPicker)}
          style={{ backgroundColor: color }}
        >
          {showColorPicker ? 'Закрити палітру кольорів' : 'Open Color Picker'}
        </Button>
        {showColorPicker && (
          <Box mt={2}>
            <SketchPicker
              color={color}
              onChangeComplete={handleColorChange}
            />
          </Box>
        )}
      </Box>
      <Box mt={2}>
        <Typography id="font-size-slider" gutterBottom>
          Розмір шрифту
        </Typography>
        <Slider
          aria-labelledby="font-size-slider"
          valueLabelDisplay="auto"
          step={1}
          marks
          min={8}
          max={24}
          onChange={handleFontSizeChange}
        />
      </Box>
    </Paper>
  );
};

export default CustomizationPanel;