import React, { useState } from 'react';
import { Typography, Box, CircularProgress, List, ListItem, ListItemText } from '@mui/material';
import FileUpload from './FileUpload';

function UploadPage() {
  const [uploadStatus, setUploadStatus] = useState([]);

  const handleUploadStatus = (status) => {
    setUploadStatus(prevStatus => [...prevStatus, status]);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Завантаження документів
      </Typography>
      <FileUpload onUploadStatus={handleUploadStatus} />
      {uploadStatus.length > 0 && (
        <Box mt={2}>
          <Typography variant="h6">Upload Status:</Typography>
          <List>
            {uploadStatus.map((status, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={status.file}
                  secondary={`Статус: ${status.status}, Прогрес: ${status.progress}`}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
}

export default UploadPage;