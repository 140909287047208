import React from 'react';
import { Typography, Box } from '@mui/material';
import DocumentList from './DocumentList';

const EntrepreneurDashboard = () => {
  return (
    <Box>
      <Typography variant="h4" gutterBottom>Панель підприємця</Typography>
      <Typography variant="body1" gutterBottom>
        Ласкаво просимо до системи документообігу для підприємців. Тут ви можете керувати своїми документами.
      </Typography>
      <DocumentList />
    </Box>
  );
};

export default EntrepreneurDashboard;