import React, { useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { uk } from 'date-fns/locale';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import Layout from './components/Layout';
import Login from './components/Login';
import DocumentList from './components/DocumentList';
import UploadPage from './components/UploadPage';
import DocumentDetail from './components/DocumentDetail';
import Chat from './components/Chat';
import AdminPanel from './components/AdminPanel';
import EntrepreneurDashboard from './components/EntrepreneurDashboard';
import OrganizationDashboard from './components/OrganizationDashboard';
import PrivateRoute from './components/PrivateRoute';
import { getToken, refreshToken, logout } from './services/authService';
import { CircularProgress } from '@mui/material';
import ErrorBoundary from './ErrorBoundary';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3a8cff',
    },
    secondary: {
      main: '#9c27b0',
    },
    background: {
      default: '#f8f8f8',
      paper: '#ffffff',
    },
    text: {
      primary: '#333333',
      secondary: '#666666',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

function AuthWrapper() {
  const navigate = useNavigate();
  const { isAuthenticated, logout, checkTokenExpiration } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
    const checkAuth = async () => {
      try {
        await checkTokenExpiration();
      } catch (error) {
        console.error('Error checking authentication:', error);
        logout();
        navigate('/login');
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, [navigate, logout, checkTokenExpiration]);


  if (isLoading) {
    return <CircularProgress />;
  }

  return null;
}

function App() {
  const { logout, checkTokenExpiration } = useAuth();

  useEffect(() => {
    const checkAuthAndRefresh = async () => {
      try {
        await checkTokenExpiration();
      } catch (error) {
        console.error('Error checking authentication:', error);
        logout();
      }
    };

    checkAuthAndRefresh();

    const refreshInterval = setInterval(checkAuthAndRefresh, 15 * 60 * 1000); // Check every 15 minutes

    return () => clearInterval(refreshInterval);
  }, [logout, checkTokenExpiration]);




  return (
    <ThemeProvider theme={lightTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={uk}>
        <AuthProvider>
          <ErrorBoundary>
          <Router>
            <AuthWrapper />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route element={<Layout />}>
                <Route path="/" element={<Navigate to="/documents" replace />} />
               <Route
                      path="/documents"
                      element={
                        <PrivateRoute>
                          <DocumentList />
                        </PrivateRoute>
                      }
                    />
                  <Route
                      path="/documents/:id"
                      element={
                        <PrivateRoute requiredRole="PRIVATE_ENTREPRENEUR">
                          <DocumentDetail />
                        </PrivateRoute>
                      }
                    />
                <Route
                  path="/upload"
                  element={
                    <PrivateRoute>
                      <UploadPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/document/:id"
                  element={
                    <PrivateRoute>
                      <DocumentDetail />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/chat"
                  element={
                    <PrivateRoute>
                      <Chat />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/admin"
                  element={
                    <PrivateRoute requiredRole="admin">
                      <AdminPanel />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/entrepreneur-dashboard"
                  element={
                    <PrivateRoute requiredRole="PRIVATE_ENTREPRENEUR">
                      <EntrepreneurDashboard />
                    </PrivateRoute>
                  }
                />
              </Route>
            </Routes>
          </Router>
          </ErrorBoundary>
        </AuthProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;