import React, { createContext, useState, useContext, useEffect, useCallback, useMemo } from 'react';
import {
  setAuthToken,
  getToken,
  getUserInfo,
  isAuthenticated as isAuthenticatedService,
  login as loginService,
  logout as logoutService,
  refreshToken,
  getRefreshToken,
  setTokens,
  clearTokens,
  isTokenExpired
} from '../services/authService';
import { jwtDecode } from "jwt-decode";
import api from '../services/api';
import { TOKEN_KEY, REFRESH_TOKEN_KEY } from '../services/authService';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const getToken = useCallback(() => {
    return localStorage.getItem(TOKEN_KEY);
  }, []);

  const logout = useCallback(() => {
    logoutService();
    setIsAuthenticated(false);
    setUser(null);
    clearTokens();
  }, []);

  const updateUser = useCallback((userData) => {
    setUser(prevUser => ({ ...prevUser, ...userData }));
  }, []);

  const loadUser = useCallback(async (token) => {
    try {
      setAuthToken(token);
      const userDetailsResponse = await api.get('/user-details');
      const userDetails = userDetailsResponse.data;
      setUser(userDetails);
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Error loading user:", error);
      setUser(null);
      setIsAuthenticated(false);
      setAuthToken(null);
    } finally {
      setLoading(false);
    }
  }, []);

  const login = useCallback(async (username, password) => {
    try {
      const data = await loginService(username, password);
      setTokens(data.access_token, data.refresh_token);
      await loadUser(data.access_token);
      setIsAuthenticated(true);
      return data;
    } catch (error) {
      console.error('Login error:', error);
      setIsAuthenticated(false);
      throw error;
    }
  }, [loadUser]);

  const ecpLogin = useCallback(async (access_token, refresh_token) => {
    try {
      setTokens(access_token, refresh_token);
      const userInfo = jwtDecode(access_token);
      setUser({
        ...userInfo,
        role: userInfo.role || 'USER'
      });
      setIsAuthenticated(true);
    } catch (error) {
      console.error('ECP Login error:', error);
      logout();
    }
  }, [logout]);

  const updateUserRole = useCallback((role) => {
    setUser(prevUser => prevUser ? {...prevUser, role} : null);
  }, []);

  const checkTokenExpiration = useCallback(async () => {
    const token = getToken();
    const refreshTokenValue = getRefreshToken();
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          if (refreshTokenValue) {
            const refreshed = await refreshToken();
            if (!refreshed) {
              logout();
            } else {
              await loadUser(getToken());
            }
          } else {
            logout();
          }
        } else if (!user) {
          await loadUser(token);
        }
      } catch (error) {
        console.error('Token validation error:', error);
        logout();
      }
    } else {
      logout();
    }
    setLoading(false);
  }, [getToken, loadUser, logout, user]);

  useEffect(() => {
    checkTokenExpiration();
  }, [checkTokenExpiration]);

  const hasPermission = useCallback((permission) => {
    return user?.permissions?.includes(permission) || false;
  }, [user]);

  const contextValue = useMemo(() => ({
    isAuthenticated,
    user,
    loading,
    login,
    logout,
    updateUser,
    ecpLogin,
    checkTokenExpiration,
    hasPermission,
    updateUserRole,
    setLoading,
    getToken,
  }), [
    isAuthenticated,
    user,
    loading,
    login,
    logout,
    updateUser,
    ecpLogin,
    checkTokenExpiration,
    hasPermission,
    updateUserRole,
    getToken
  ]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};