// ErrorBoundary.js
import React from 'react';
import { Typography, Button } from '@mui/material';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
    // You can also send error reports to a logging service here
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <Typography variant="h5">Something went wrong.</Typography>
          <Typography variant="body1">{this.state.error.toString()}</Typography>
          <Button onClick={() => window.location.reload()}>Reload Page</Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;