import React, { useRef, useState } from 'react';
import { Button, CircularProgress, Snackbar } from '@mui/material';
import axios from 'axios';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { getToken, refreshToken, setAuthToken } from '../services/authService';

const acceptedFileTypes = '.pdf,.doc,.docx,.txt,.jpg,.jpeg,.png,.xls,.xlsx,.ppt,.pptx';

function FileUpload({ onFileUploaded }) {
  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const files = event.target.files;
    if (files.length === 0) return;

    setIsUploading(true);
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    try {
      let token = getToken();
      if (!token) {
        throw new Error('No authentication token found');
      }

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/upload-multiple`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });

      console.log('Upload response:', response.data);

      if (response.data && response.data.documents) {
        response.data.documents.forEach(doc => {
          console.log('Processing uploaded document:', doc);
          onFileUploaded(doc);
        });
        setSnackbarMessage('Файли успішно завантажено');
      } else {
        console.log('Unexpected response structure:', response.data);
        setSnackbarMessage('Неочікувана відповідь сервера');
      }
    } catch (error) {
      console.error('Upload failed', error);
      if (error.response && error.response.status === 401) {
        try {
          await refreshToken();
          // Retry the upload after refreshing the token
          handleFileChange(event);
        } catch (refreshError) {
          console.error('Error refreshing token:', refreshError);
          setSnackbarMessage('Не вдалося авторизуватись. Будь ласка, увійдіть ще раз.');
        }
      } else {
        setSnackbarMessage('Не вдалося завантажити. Будь ласка, спробуйте ще раз.');
      }
    } finally {
      setIsUploading(false);
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <input
        type="file"
        multiple
        accept={acceptedFileTypes}
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      <Button
        variant="contained"
        component="label"
        color="secondary"
        startIcon={<CloudUploadIcon />}
        style={{ marginTop: '16px' }}
        onClick={handleUploadClick}
        disabled={isUploading}
      >
        {isUploading ? <CircularProgress size={24} /> : 'Завантажити'}
      </Button>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </>
  );
}

export default FileUpload;