// src/services/errorMonitoring.js
class ErrorMonitoring {
  constructor() {
    this.errors = [];
  }

  capture(error, context = {}) {
    const errorLog = {
      timestamp: new Date().toISOString(),
      error: {
        message: error.message,
        stack: error.stack,
        code: error.code,
        response: error.response?.data
      },
      context,
      userAgent: navigator.userAgent
    };

    this.errors.push(errorLog);

    // Log to console in development
    if (process.env.NODE_ENV === 'development') {
      console.error('Error captured:', errorLog);
    }

    // Here you could send to your error tracking service
    // Example with Sentry:
    // if (typeof Sentry !== 'undefined') {
    //   Sentry.captureException(error, { extra: context });
    // }
  }

  getErrors() {
    return this.errors;
  }

  clearErrors() {
    this.errors = [];
  }
}

export const errorMonitoring = new ErrorMonitoring();