import React from 'react';
import { Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Description, VpnKey, Fingerprint } from '@mui/icons-material';

const SigningOptionsDialog = ({ open, onClose, onSelectOption }) => {
  const options = [
    { name: 'Дія.підпис', icon: <Description />, key: 'diia' },
    { name: 'ЕПЦ ключ(КЕП)', icon: <VpnKey />, key: 'ecp' },
    { name: 'SmartID', icon: <Fingerprint />, key: 'smartid' }
  ];

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Виберіть спосіб підписання</DialogTitle>
      <DialogContent>
        <List>
          {options.map((option) => (
            <ListItem button key={option.key} onClick={() => onSelectOption(option.key)}>
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText primary={option.name} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default SigningOptionsDialog;