import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography, Button, IconButton, TextField, Box, Chip,
  Dialog, DialogTitle, DialogContent, DialogActions,
  CircularProgress, Tooltip, Alert, Snackbar
} from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';
import {
  Edit as EditIcon,
  Share as ShareIcon,
  Chat as ChatIcon,
  Key as KeyIcon,
  Label as LabelIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import { QRCodeSVG } from 'qrcode.react';
import SigningOptionsDialog from './SigningOptionsDialog';
import SignPopup from './SignPopup';
import { isAuthenticated, refreshToken, logout } from '../services/authService';
import DigitalStamp from './DigitalStamp';
import api from '../services/api';
import DocumentViewer from './DocumentViewer';
import DocumentDownloadHandler from './DocumentDownloadHandler';
import { errorMonitoring } from '../services/errorMonitoring';

function DocumentDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [documentData, setDocumentData] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState('');
  const [isTagDialogOpen, setIsTagDialogOpen] = useState(false);
  const [newTag, setNewTag] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isDocumentLoading, setIsDocumentLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [signingDialogOpen, setSigningDialogOpen] = useState(false);
  const [signingMethod, setSigningMethod] = useState('');
  const [showQRDialog, setShowQRDialog] = useState(false);
  const [signPopupOpen, setSignPopupOpen] = useState(false);
  const [signatureInfo, setSignatureInfo] = useState(null);

  const showSnackbar = useCallback((message, severity = 'info') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  }, []);

  const fetchDocument = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`/documents/${id}`);
      setDocumentData(response.data);
      setNewTitle(response.data.title);
      setFileUrl(`${process.env.REACT_APP_API_URL}/documents/${id}/content`);
    } catch (error) {
      console.error('Error fetching document:', error);
      setError('Document not found or you don\'t have permission to view it.');
      setDocumentData(null);
      errorMonitoring.capture(error, { documentId: id });
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  const handleSignatureCheck = useCallback(async () => {
    try {
      const response = await api.get(`/documents/${id}/signature-info`);
      setSignatureInfo(response.data);
      return response.data;
    } catch (error) {
      console.error('Error checking signature:', error);
      errorMonitoring.capture(error, { documentId: id });
      return null;
    }
  }, [id]);

  useEffect(() => {
    if (documentData?.is_signed) {
      handleSignatureCheck();
    }
  }, [documentData, handleSignatureCheck]);

  useEffect(() => {
    const checkAuth = async () => {
      if (!isAuthenticated()) {
        const refreshed = await refreshToken();
        if (!refreshed) {
          logout();
          navigate('/login');
        }
      }
    };

    checkAuth();
    fetchDocument();
  }, [navigate, fetchDocument]);

  const handleChatClick = useCallback(() => {
    localStorage.setItem('chatDocumentId', id);
    navigate('/chat');
  }, [id, navigate]);

  // Add this function with the other handlers
const handleDeleteTag = async (tagToDelete) => {
  try {
    await api.delete(`/documents/${id}/tags`, {
      data: { tag: tagToDelete }
    });

    // Update document data to reflect the change
    setDocumentData(prevDoc => ({
      ...prevDoc,
      tags: prevDoc.tags.filter(tag => tag !== tagToDelete)
    }));

    showSnackbar('Тег успішно видалено', 'success');
  } catch (error) {
    console.error('Error deleting tag:', error);
    showSnackbar('Не вдалося видалити тег', 'error');
    errorMonitoring.capture(error, {
      documentId: id,
      tag: tagToDelete,
      action: 'delete_tag'
    });
  }
};

  const handleEditTitle = async () => {
    try {
      const response = await api.patch(`/documents/${id}`, { title: newTitle });
      setDocumentData(prevDoc => ({ ...prevDoc, title: response.data.title }));
      setIsEditing(false);
      showSnackbar('Назву документа оновлено', 'success');
    } catch (error) {
      console.error('Error updating document title:', error);
      showSnackbar('Не вдалося оновити назву документа', 'error');
      errorMonitoring.capture(error, { documentId: id });
    }
  };

  const handleAddTag = async () => {
    try {
      await api.post(`/documents/${id}/tags`, { tag: newTag });
      await fetchDocument();
      setNewTag('');
      setIsTagDialogOpen(false);
      showSnackbar('Тег додано', 'success');
    } catch (error) {
      console.error('Error adding tag:', error);
      showSnackbar('Не вдалося додати тег', 'error');
      errorMonitoring.capture(error, { documentId: id });
    }
  };

  const handleSignDocument = () => {
    setSigningDialogOpen(true);
  };

  const handleSelectSigningOption = (option) => {
    setSigningMethod(option);
    if (option === 'diia' || option === 'smartid') {
      setShowQRDialog(true);
    } else if (option === 'ecp') {
      setSignPopupOpen(true);
    }
    setSigningDialogOpen(false);
  };

  const handleDocumentSigned = async (signatureData) => {
    try {
      const response = await api.post(`/documents/${id}/sign`, {
        signature_method: 'ecp',
        signature_data: signatureData,
      });

      setDocumentData(prevDoc => ({
        ...prevDoc,
        is_signed: response.data.is_signed,
        status: response.data.status,
        signature_date: response.data.signature_date,
        signature_path: response.data.signature_path
      }));

      await handleSignatureCheck();
      showSnackbar('Документ успішно підписано', 'success');
    } catch (error) {
      console.error('Error signing document:', error);
      showSnackbar('Помилка підписання документа', 'error');
      errorMonitoring.capture(error, { documentId: id });
    } finally {
      setSignPopupOpen(false);
    }
  };
  const renderFileContent = useCallback(() => {
    if (!documentData || !fileUrl) {
      return <Typography color="error">Неможливо відобразити вміст документа. Відсутні дані.</Typography>;
    }

    const fileExtension = documentData.file_path.split('.').pop().toLowerCase();

    return (
      <Box sx={{ width: '100%', position: 'relative' }}>
        <DocumentViewer
          fileUrl={fileUrl}
          fileType={fileExtension}
          onLoad={() => {
            setIsDocumentLoading(false);
          }}
          onError={(error) => {
            console.error('Error loading document:', error);
            setIsDocumentLoading(false);
            setError('Помилка завантаження документу');
            errorMonitoring.capture(error, { documentId: id });
          }}
        />
        {documentData.is_signed && signatureInfo && (
          <DigitalStamp signatureInfo={signatureInfo} />
        )}
      </Box>
    );
  }, [documentData, fileUrl, id, signatureInfo]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (!documentData) {
    return <Typography>Документ не знайдено</Typography>;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Button
          onClick={() => navigate('/')}
          variant="outlined"
          startIcon={<KeyboardArrowLeft />}
        >
          Назад
        </Button>
        <Typography variant="h6">{documentData.title}</Typography>
        <Box>
          <IconButton
            onClick={() => setIsEditing(true)}
            disabled={documentData.is_signed}
            title={documentData.is_signed ? "Підписаний документ не можна редагувати" : "Редагувати назву"}
          >
            <EditIcon />
          </IconButton>

          <Tooltip title="Чат з документом">
            <IconButton onClick={handleChatClick}>
              <ChatIcon />
            </IconButton>
          </Tooltip>

          <IconButton
            onClick={handleSignDocument}
            disabled={documentData.is_signed}
            title={documentData.is_signed ? "Документ вже підписано" : "Підписати документ"}
          >
            <KeyIcon />
          </IconButton>

          <DocumentDownloadHandler
            documentId={id}
            documentTitle={documentData.title}
            signatureInfo={signatureInfo}
            onSignatureCheck={handleSignatureCheck}
            onError={(error) => {
              showSnackbar(error.message, 'error');
            }}
            onSuccess={(type) => {
              showSnackbar(
                `Документ успішно завантажено${type === 'signed' ? ' (підписана версія)' : ''}`,
                'success'
              );
            }}
          />
        </Box>
      </Box>

      {isEditing && (
        <Box mb={2}>
          <TextField
            fullWidth
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
            label="Нова назва документа"
            variant="outlined"
            size="small"
          />
          <Box sx={{ mt: 1, display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
            <Button onClick={() => setIsEditing(false)} color="inherit">
              Відмінити
            </Button>
            <Button onClick={handleEditTitle} variant="contained">
              Зберегти
            </Button>
          </Box>
        </Box>
      )}

      <Box mb={2} sx={{
        border: '1px solid #ccc',
        borderRadius: '4px',
        overflow: 'hidden',
        maxHeight: '80vh',
        overflowY: 'auto'
      }}>
        {renderFileContent()}
      </Box>

      <Box mt={2}>
        {documentData.tags?.map((tag) => (
          <Chip
            key={tag}
            label={tag}
            onDelete={documentData.is_signed ? undefined : () => handleDeleteTag(tag)}
            sx={{ mr: 1, mb: 1 }}
          />
        ))}
        {!documentData.is_signed && (
          <Chip
            icon={<LabelIcon />}
            label="Додати тег"
            onClick={() => setIsTagDialogOpen(true)}
            variant="outlined"
          />
        )}
      </Box>

      <SigningOptionsDialog
        open={signingDialogOpen}
        onClose={() => setSigningDialogOpen(false)}
        onSelectOption={handleSelectSigningOption}
      />

      <SignPopup
        open={signPopupOpen}
        onClose={() => setSignPopupOpen(false)}
        documentId={id}
        onDocumentSigned={handleDocumentSigned}
      />

      <Dialog
        open={showQRDialog}
        onClose={() => setShowQRDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {signingMethod === 'diia' ? 'Підпишіть з Дія.підпис' : 'Підпишіть за допомогою Smart ID'}
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            Відскануйте QR-код своїм додатком, щоб підписати документ:
          </Typography>
          <Box display="flex" justifyContent="center" mt={2}>
            <QRCodeSVG
              value={`https://signing-url.com/${id}/${signingMethod}`}
              size={200}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowQRDialog(false)}>Закрити</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isTagDialogOpen}
        onClose={() => setIsTagDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Додати новий тег</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Новий тег"
            fullWidth
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleAddTag()}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsTagDialogOpen(false)}>Відміна</Button>
          <Button onClick={handleAddTag} variant="contained">
            Додати
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default DocumentDetail;