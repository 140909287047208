import React from 'react';
import { Typography, Box } from '@mui/material';

const OrganizationDashboard = () => {
  return (
    <Box>
      <Typography variant="h4">Organization Dashboard</Typography>
      {/* Add more content here */}
    </Box>
  );
};

export default OrganizationDashboard;