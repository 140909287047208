import React, { useState, useEffect, useCallback } from 'react';
import { EndUser } from 'euscp';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Box, Button, TextField, Typography, CircularProgress, Select, MenuItem, Snackbar, Alert } from '@mui/material';
import { jwtDecode } from "jwt-decode";
import { UserType } from './userTypes';

const ECPAuth = () => {
    const [keyFile, setKeyFile] = useState(null);
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [euSign, setEuSign] = useState(null);
    const [keyFileFormat, setKeyFileFormat] = useState('jks');
    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
    const { ecpLogin } = useAuth();

    useEffect(() => {
        const initializeEUSign = async () => {
            try {
                const euSignInstance = new EndUser();
                await euSignInstance.Initialize({
                    language: "uk",
                    encoding: "utf-8",
                    httpProxyServiceURL: "http://127.0.0.1:8080/Server/ProxyHandler",
                    directAccess: true,
                    CAs: "/data/CAs.json",
                    CACertificates: "/data/CACertificates.p7b"
                });
                setEuSign(euSignInstance);
                console.log("EUSign initialized successfully");
            } catch (error) {
                console.error("Failed to initialize EUSign:", error);
                setError('Помилка ініціалізації EUSign');
            }
        };

        initializeEUSign();
    }, []);

    const readFileAsArrayBuffer = useCallback((file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => resolve(event.target.result);
            reader.onerror = (error) => reject(error);
            reader.readAsArrayBuffer(file);
        });
    }, []);

    const handleFileChange = (e) => {
        setKeyFile(e.target.files[0]);
    };

    const extractTaxNumber = useCallback((privateKeyInfo) => {
        if (privateKeyInfo.subjDRFOCode) {
            return privateKeyInfo.subjDRFOCode;
        }

        const subjectInfo = privateKeyInfo.subject || '';
        const taxNumberMatch = subjectInfo.match(/(?:РНОКПП|ІПН):\s*(\d+)/);
        if (taxNumberMatch) {
            return taxNumberMatch[1];
        }

        return null;
    }, []);

     const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            if (!keyFile) {
                throw new Error('Будь ласка, оберіть файл ключа');
            }

            const keyData = await readFileAsArrayBuffer(keyFile);
            const keyDataUint8 = new Uint8Array(keyData);

            let keyInfo;
            let jksKeys;

            if (keyFileFormat === 'jks') {
                jksKeys = await euSign.GetJKSPrivateKeys(keyDataUint8);
                if (jksKeys && jksKeys.length > 0) {
                    keyInfo = await euSign.ReadPrivateKeyBinary(jksKeys[0].privateKey, password);
                } else {
                    throw new Error("No keys found in JKS file");
                }
            } else {
                keyInfo = await euSign.ReadPrivateKeyBinary(keyDataUint8, password);
            }

            console.log("Raw key info:", keyInfo);

            let taxNumber;
            let userType;
            let username;

            if (keyInfo.subjEDRPOUCode && keyInfo.subjOrg !== 'ФІЗИЧНА ОСОБА') {
                userType = UserType.ORGANIZATION;
                taxNumber = keyInfo.subjEDRPOUCode;
                username = `org_${keyInfo.subjEDRPOUCode}_${keyInfo.subjDRFOCode}`;
            } else {
                userType = UserType.PRIVATE_ENTREPRENEUR;
                taxNumber = keyInfo.subjDRFOCode;
                username = `pe_${keyInfo.subjDRFOCode}`;
            }

            if (!taxNumber) {
                throw new Error('Failed to extract tax number from the key');
            }

            const data = "Authentication request";
            const signature = await euSign.SignDataInternal(true, data);

            const ecpResponse = await axios.post(`${process.env.REACT_APP_API_URL}/auth/ecp`, {
                taxNumber,
                signature: btoa(String.fromCharCode.apply(null, signature)),
                userType,
                username,
                keyInfo: {
                    subjOrg: keyInfo.subjOrg,
                    subjTitle: keyInfo.subjTitle,
                    subjDRFOCode: keyInfo.subjDRFOCode,
                    subjEDRPOUCode: keyInfo.subjEDRPOUCode,
                    subjFullName: keyInfo.subjFullName,
                    subjLocality: keyInfo.subjLocality
                }
            });

            console.log("ECP Authentication successful", ecpResponse.data);

            const { access_token, refresh_token } = ecpResponse.data;

            await ecpLogin(access_token, refresh_token);

            const userInfo = jwtDecode(access_token);
            let redirectPath = '/documents';
            let message = 'Успішна аутентифікація.';



            setSnackbar({
                open: true,
                message: message,
                severity: 'success'
            });

            // Redirect all users to /documents
            navigate('/documents');

        } catch (error) {
            console.error('Authentication error:', error);
            if (error.response?.status === 401) {
                setError('Неправильні облікові дані. Будь ласка, перевірте ваш ключ ЕЦП та спробуйте ще раз.');
            } else if (error.response?.status === 400 && error.response.data.detail === "An account already exists") {
                setError('Обліковий запис вже існує в системі. Будь ласка, зверніться до адміністратора.');
            } else {
                setError(`Помилка аутентифікації: ${error.response?.data?.detail || error.message}`);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <input
                accept=".jks,.dat,.pfx,.key"
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                onChange={handleFileChange}
            />
            <label htmlFor="raised-button-file">
                <Button variant="contained" component="span" fullWidth>
                    {keyFile ? keyFile.name : 'Обрати файл ключа'}
                </Button>
            </label>
            <Select
                value={keyFileFormat}
                onChange={(e) => setKeyFileFormat(e.target.value)}
                fullWidth
            >
                <MenuItem value="jks">JKS файл</MenuItem>
                <MenuItem value="dat">DAT файл</MenuItem>
            </Select>
            <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Пароль ключа"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={!keyFile || !password || loading}
            >
                {loading ? <CircularProgress size={24} /> : 'Аутентифікація з ЕЦП'}
            </Button>
            {error && (
                <Typography color="error" align="center">
                    {error}
                </Typography>
            )}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
            >
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default ECPAuth;