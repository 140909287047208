// Layout.js
import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import api from '../services/api';
import { UserType } from './userTypes';

const Layout = ({ children }) => {
  const { user, logout, updateUser } = useAuth();

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await api.get('/user-role');
        console.log('User role response:', response.data);
        updateUser({ role: response.data.role });
      } catch (error) {
        console.error('Error fetching user role:', error);
      }
    };

    if (user && !user.role) {
      fetchUserRole();
    }
  }, [user, updateUser]);


  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}><b>LITOPYS</b></Typography>
          {user && user.role === 'admin' && (
            <Button color="inherit" component={Link} to="/admin">
              Адмін панель
            </Button>
          )}
          {user && user.userType === UserType.PRIVATE_ENTREPRENEUR && (
            <Button color="inherit" component={Link} to="/entrepreneur-dashboard">
              Панель підприємця
            </Button>
          )}
          <Button color="inherit" component={Link} to="/documents">Документи</Button>
          <Button color="inherit" component={Link} to="/chat">ЧАТ</Button>
          <Button color="inherit" component={Link} to="/upload">Завантаження</Button>
          <Button color="inherit" onClick={logout}>Вихід</Button>
        </Toolbar>
      </AppBar>
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default Layout;