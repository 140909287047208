import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import {
  TextField, Button, Typography, Paper, CircularProgress, Grid, Box, Chip,
  ListItem, ListItemText, Accordion, AccordionSummary, AccordionDetails,
  IconButton, Snackbar, Checkbox, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions, Switch, Table, TableContainer, TableHead, TableCell, TableBody, Divider, Alert, AlertTitle,
  MenuItem, Select, FormControl, InputLabel
} from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { createTheme, useTheme, styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ChatIcon from '@mui/icons-material/Chat';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import EmailIcon from '@mui/icons-material/Email';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Chart from './Chart';
import CustomizationPanel from './CustomizationPanel';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { useAuth } from '../contexts/AuthContext';
import api from '../services/api';
import { getToken } from '../services/authService';


const ITEMS_PER_PAGE = 20;
const DEFAULT_LIST_HEIGHT = 400;


const DarkPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: '#1e1e1e',
  color: '#e0e0e0',
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
}));

const LightPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: '#f8f8f8',
  color: '#333',
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#ccc',
    },
    '&:hover fieldset': {
      borderColor: '#999',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: '#666',
  },
  '& .MuiInputBase-input': {
    color: '#333',
  },
}));

const StyledTextFieldDark = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#444',
    },
    '&:hover fieldset': {
      borderColor: '#666',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: '#aaaaaa',
  },
  '& .MuiInputBase-input': {
    color: '#ffffff',
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Blue color for primary (will be used for "Історія чату" button)
    },
    secondary: {
      main: '#9c27b0', // Purple color for secondary (will be used for "Надіслати" button)
    },
  },
});



const Chat = () => {
  const [query, setQuery] = useState('');
  const [response, setResponse] = useState('');
  const [relatedDocuments, setRelatedDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [documents, setDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [chartType, setChartType] = useState('bar');
  const [xKey, setXKey] = useState('');
  const [yKey, setYKey] = useState('');
  const [chartRequested, setChartRequested] = useState(false);
  const [selectedXAxis, setSelectedXAxis] = useState('');
  const [selectedYAxis, setSelectedYAxis] = useState('');
  const [suggestedChartTypes, setSuggestedChartTypes] = useState([]);
  const [availableAxes, setAvailableAxes] = useState([]);
  const [selectedChartType, setSelectedChartType] = useState('');
  const [chartGenerationStep, setChartGenerationStep] = useState(0);
  const [chartCustomizations, setChartCustomizations] = useState({});
  const [dataTransformations, setDataTransformations] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [availableFiles, setAvailableFiles] = useState([]);
  const [excelData, setExcelData] = useState(null);
  const [availableColumns, setAvailableColumns] = useState([]);
  const [chartSuggestions, setChartSuggestions] = useState([]);
  const [containerHeight, setContainerHeight] = useState(400);
  const [filters, setFilters] = useState([]);
  const [filterColumn, setFilterColumn] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const responseRef = useRef(null);
  const sortedRelatedDocuments = relatedDocuments.sort((a, b) => b.relevance_score - a.relevance_score);
  const [isDocumentsFetching, setIsDocumentsFetching] = useState(true);
  const { user, getToken, isAuthenticated } = useAuth();

  useEffect(() => {
  const fetchDocuments = async () => {
    setIsDocumentsFetching(true);
    try {
      let token;
      if (typeof getToken === 'function') {
        token = getToken();
      } else if (user && user.token) {
        token = user.token;
      }

      if (!token) {
        setError('No authentication token found. Please log in.');
        return;
      }

      const response = await api.get('/documents', {
        params: { include_all: user && user.role === 'ADMIN' },
        headers: { Authorization: `Bearer ${token}` }
      });

      console.log('Fetched documents:', response.data);
      if (Array.isArray(response.data.documents)) {
        setDocuments(response.data.documents);
      } else if (Array.isArray(response.data)) {
        setDocuments(response.data);
      } else {
        console.error('Unexpected response format:', response.data);
        setDocuments([]);
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
      setError('Failed to fetch documents. Please try again later.');
    } finally {
      setIsDocumentsFetching(false);
    }
  };

  fetchDocuments();
}, [getToken, user]);

  useEffect(() => {
    console.log('Container height:', containerHeight);
  }, [containerHeight]);

  useEffect(() => {
    console.log('Chart Generation Step:', chartGenerationStep);
    console.log('Suggested Chart Types:', suggestedChartTypes);
    console.log('Available Axes:', availableAxes);
  }, [chartGenerationStep, suggestedChartTypes, availableAxes]);

  useEffect(() => {
    if (query.toLowerCase().includes('show me a') && query.toLowerCase().includes('chart')) {
      handleSubmit({ preventDefault: () => {} });
    }
  }, [query]);

  useEffect(() => {
    console.log('Response updated:', response);
  }, [response]);

const commandLinks = [
    { text: "Узагальни документ", command: "Узагальни документ " },
    { text: "Узагальни мої документи про [тема]", command: "Узагальни мої документи про " },
    { text: "Візуалізуй дані", command: "Візуалізуй дані " },
    { text: "Створити лист", command: "Створити лист " }
  ];

  const handleCommandClick = (command) => {
    setQuery(command);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

const showSnackbar = (message, severity = 'info') => {
  setSnackbar({ open: true, message, severity });
};

  const fetchDocuments = async () => {
    try {
      const response = await axios.get('/documents');
      setDocuments(response.data);
    } catch (error) {
      console.error('Помилка отримання документів:', error);
      setError('Не вдалося отримати список документів. Будь ласка, спробуйте ще раз.');
    }
  };

  const handleChartCustomization = (customizations) => {
    setChartCustomizations(prev => ({ ...prev, ...customizations }));
  };

  const addFilter = () => {
    if (filterColumn && filterValue) {
      setFilters([...filters, { column: filterColumn, value: filterValue }]);
      setFilterColumn('');
      setFilterValue('');
    }
  };

  const removeFilter = (index) => {
    setFilters(filters.filter((_, i) => i !== index));
  };

  const applyDataTransformation = (transformation) => {
    setDataTransformations(prev => [...prev, transformation]);
    const transformedData = applyTransformation(chartData, transformation);
    setChartData(transformedData);
  };

  const applyTransformation = (data, transformation) => {
    switch (transformation.type) {
      case 'filter':
        return data.filter(item => item[transformation.column] > transformation.value);
      case 'aggregate':
        // Implement aggregation logic
        break;
      case 'sort':
        return [...data].sort((a, b) => a[transformation.column] - b[transformation.column]);
      default:
        return data;
    }
  };

  const truncateText = useCallback((text, maxLength) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  }, []);

  const reversedChatHistory = useMemo(() => [...chatHistory].reverse(), [chatHistory]);

  const renderChatHistory = useCallback(() => (
  <Box maxHeight={400} overflow="auto">
    {chatHistory.map((item, index) => (
      <ListItem key={index}>
        <ListItemText
          primary={
            <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
              Q: {truncateText(item.query, 100)}
            </Typography>
          }
          secondary={
            <>
              <Typography variant="body2">
                A: {typeof item.response === 'string' ? truncateText(item.response, 150) : 'Chart data'}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {item.timestamp.toLocaleString()}
              </Typography>
              {item.isChart && (
                <Typography variant="body2">
                  Chart data available
                </Typography>
              )}
            </>
          }
        />
      </ListItem>
    ))}
  </Box>
), [chatHistory, truncateText]);

  const handleExcelVisualization = async (file) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      setExcelData(jsonData);
      const columns = Object.keys(jsonData[0]);
      setAvailableColumns(columns);

      try {
        const response = await axios.post('/process-excel', { data: jsonData });
        setChartData(response.data.chartData);
        setChartType(response.data.suggestedChartType);
        setXKey(response.data.xKey);
        setYKey(response.data.yKey);
        setSuggestedChartTypes(response.data.suggestedChartTypes);
        setResponse(`Проаналізувавши ваш файл Excel, ось кілька варіантів діаграм на основі ваших даних: ${response.data.suggestedChartTypes.join(', ')}. I've created a ${response.data.suggestedChartType} chart using ${response.data.xKey} and ${response.data.yKey} as axes. You can ask me to change the chart type or axes if you'd like.`);
      } catch (error) {
        console.error('Error processing Excel file:', error);
        setError('Виникла помилка при обробці файлу Excel. Будь ласка, спробуйте ще раз.');
      }
    };
    reader.readAsArrayBuffer(file);
  };

const handleResponseData = useCallback((data) => {
  if (data.type === 'chart') {
    setChartData(data.data);
    setChartType(data.chartType);
    setXKey(data.xKey);
    setYKey(data.yKey);
    setChartRequested(true);
  } else if (data.type === 'text' || data.type === 'summary' || data.type === 'email_template') {
    setResponse(data.data);
    setRelatedDocuments(data.related_documents || []);
  } else {
    console.warn('Unknown response type:', data.type);
    setError('Отримано невідомий тип відповіді від сервера.');
  }
}, []);

   // У Chat.js модифікуємо handleSubmit:

  const formatChatResponse = (response) => {
  // Разделяем ответ на секции
  const sections = response.split('\n\n');


  return (
    <Box>
      {sections.map((section, index) => {
        if (section.includes('цитата:') || section.includes('пункт')) {
          // Выделяем цитаты и пункты
          return (
            <Box
              key={index}
              sx={{
                my: 2,
                p: 2,
                borderLeft: '4px solid #3f51b5',
                bgcolor: 'rgba(63, 81, 181, 0.05)'
              }}
            >
              <Typography variant="body1">{section}</Typography>
            </Box>
          );
        }

        return (
          <Typography
            key={index}
            variant="body1"
            paragraph
            sx={{ my: 1 }}
          >
            {section}
          </Typography>
        );
      })}
    </Box>
  );
};

// Chat.js
const handleSubmit = useCallback(async (e) => {
  e.preventDefault();
  setLoading(true);
  setError('');

  try {
    if (!selectedDocuments.length) {
      throw new Error('Будь ласка, виберіть документи для аналізу');
    }

    const isVisualizationRequest = query.toLowerCase().match(/візуалізуй|діаграма|графік|показати дані/);

    if (isVisualizationRequest) {
      const visualizationResponse = await api.post('/api/v1/chart/excel-visualization', {
        document_ids: selectedDocuments,
        query,
        chart_type: chartType || undefined,
        x_axis: xKey || undefined,
        y_axis: yKey || undefined,
        filters: filters
      });

      if (visualizationResponse.data) {
        const { chart_type, x_axis, y_axis, data, available_columns, chart_suggestions, insights } = visualizationResponse.data;

        // Update state
        setChartData(data);
        setChartType(chart_type);
        setXKey(x_axis);
        setYKey(y_axis);
        setAvailableColumns(available_columns);
        setChartSuggestions(chart_suggestions);
        setChartRequested(true);

        // Set formatted response
        setResponse(
          `📊 Візуалізація даних створена\n\n` +
          `📈 Тип діаграми: ${chart_type}\n` +
          `📉 Використані осі: ${x_axis} та ${y_axis}\n\n` +
          `📋 Основні висновки:\n${insights.join('\n')}\n\n` +
          `💡 Ви можете змінити тип діаграми або осі використовуючи панель налаштувань`
        );
      }
    } else {
      const chatResponse = await api.post('/chat/', {
        query,
        document_ids: selectedDocuments
      });

      handleResponseData(chatResponse.data);
    }

    // Add to history
    setChatHistory(prev => [...prev, {
      query,
      response: response || 'Chart visualization',
      timestamp: new Date(),
      isChart: isVisualizationRequest
    }]);

  } catch (error) {
    console.error('Error in chat:', error);
    setError(
      error.response?.data?.detail ||
      error.message ||
      'Unexpected error occurred'
    );
  } finally {
    setLoading(false);
  }
}, [query, selectedDocuments, chartType, xKey, yKey, filters, handleResponseData]);

const chartTypeOptions = [
  { value: 'bar', label: 'Стовпчикова' },
  { value: 'line', label: 'Лінійна' },
  { value: 'pie', label: 'Кругова' },
  { value: 'scatter', label: 'Точкова' },
  { value: 'area', label: 'Площинна' }
];

const ChartOptionsPanel = ({
  visible,
  chartType,
  setChartType,
  xKey,
  setXKey,
  yKey,
  setYKey,
  availableColumns
}) => {
  if (!visible) return null;

  return (
    <Box sx={{ mt: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
      <Typography variant="h6" gutterBottom>
        Налаштування візуалізації
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Тип діаграми</InputLabel>
            <Select
              value={chartType}
              onChange={(e) => setChartType(e.target.value)}
              label="Тип діаграми"
            >
              {chartTypeOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Вісь X</InputLabel>
            <Select
              value={xKey}
              onChange={(e) => setXKey(e.target.value)}
              label="Вісь X"
            >
              {availableColumns.map(column => (
                <MenuItem key={column} value={column}>
                  {column}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Вісь Y</InputLabel>
            <Select
              value={yKey}
              onChange={(e) => setYKey(e.target.value)}
              label="Вісь Y"
            >
              {availableColumns.map(column => (
                <MenuItem key={column} value={column}>
                  {column}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

// Add the ChartOptionsPanel to the rendering section after the query input:
{chartRequested && (
  <ChartOptionsPanel
    visible={chartRequested}
    chartType={chartType}
    setChartType={setChartType}
    xKey={xKey}
    setXKey={setXKey}
    yKey={yKey}
    setYKey={setYKey}
    availableColumns={availableColumns}
  />
)}

  const handleChartSuggestionSelect = (suggestion) => {
    setChartType(suggestion.chart_type);
    setXKey(suggestion.x_axis);
    setYKey(suggestion.y_axis);
    handleSubmit({ preventDefault: () => {} });
  };

  const handleDocumentSelect = (documentId) => {
    setSelectedDocuments(prev =>
      prev.includes(documentId)
        ? prev.filter(id => id !== documentId)
        : [...prev, documentId]
    );
  };

const clearSelectedDocuments = () => {
  setSelectedDocuments([]);
};
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbarMessage('Текст скопійовано до буферу');
    setSnackbarOpen(true);
  };

  const handleExport = () => {
  const exportData = JSON.stringify(chatHistory, null, 2);
  const blob = new Blob([exportData], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'chat_history.json';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  setSnackbarMessage('Історію чату експортовано');
  setSnackbarOpen(true);
};

  const formatAnswer = (answer) => {
  let formattedAnswer = answer;

  if (typeof answer === 'object') {
    formattedAnswer = JSON.stringify(answer, null, 2);
  }

  const CustomParagraph = ({ children }) => {
    return React.Children.map(children, (child, index) => {
      if (React.isValidElement(child) && child.type === 'p') {
        return <Box key={index} mb={2}>{child.props.children}</Box>;
      }
      return <Typography key={index} variant="body1" gutterBottom>{child}</Typography>;
    });
  };

  return (
    <ReactMarkdown
      components={{
        p: CustomParagraph,
        h1: ({node, ...props}) => <Typography variant="h4" gutterBottom {...props} />,
        h2: ({node, ...props}) => <Typography variant="h5" gutterBottom {...props} />,
        h3: ({node, ...props}) => <Typography variant="h6" gutterBottom {...props} />,
        ul: ({node, ...props}) => <Box component="ul" ml={2} mb={2} {...props} />,
        ol: ({node, ...props}) => <Box component="ol" ml={2} mb={2} {...props} />,
        li: ({node, ...props}) => <Box component="li" mb={1} {...props} />,
        pre: ({node, ...props}) => <Box component="pre" whiteSpace="pre-wrap" mb={2} {...props} />,
        code: ({node, inline, className, children, ...props}) => {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <SyntaxHighlighter
              style={atomDark}
              language={match[1]}
              PreTag="div"
              {...props}
            >
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    >
      {formattedAnswer}
    </ReactMarkdown>
  );
};

  const commandGuide = [
  { command: "Покажи мені графік [даних]", description: "Створює графік на основі вказаних даних" },
  { command: "Тенденції в [темі]", description: "Аналізує та повідомляє про тенденції у вказаній темі" },
  { command: "Перекласти [текст] на [мову]", description: "Перекладає заданий текст на вказану мову" },
  { command: "Узагальни документ [ідентифікатор_документа]", description: "Надає короткий зміст вказаного документа" },
  { command: "Узагальни мої документи про [тема]", description: "Підсумовує декілька документів, пов'язаних із заданою темою" },
  { command: "Візуалізувати дані з [назва документа]", description: "Створює діаграму на основі даних у вказаному документі Excel" },
  { command: "Створити лист з [назва документа]", description: "Створює шаблон листа на основі даних у вказаному документі" },
];


return (
    <Box sx={{ backgroundColor: '#f8f8f8', minHeight: '100vh', padding: 3 }}>
      <Typography variant="h4" gutterBottom color="text.primary">Чат з документами</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">Виберіть документи ({selectedDocuments.length} вибрано)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box maxHeight={400} overflow="auto">
                {isDocumentsFetching ? (
                  <CircularProgress />
                ) : Array.isArray(documents) && documents.length > 0 ? (
                  documents.map((doc) => (
                    <ListItem key={doc.id} button onClick={() => handleDocumentSelect(doc.id)}>
                      <Checkbox
                        edge="start"
                        checked={selectedDocuments.includes(doc.id)}
                        tabIndex={-1}
                        disableRipple
                      />
                      <ListItemText primary={doc.title} />
                    </ListItem>
                  ))
                ) : (
                  <Typography>Немає доступних документів</Typography>
                )}
              </Box>
              {selectedDocuments.length > 0 && (
                <Button onClick={clearSelectedDocuments} variant="outlined" color="secondary" style={{ marginTop: '10px' }}>
                  Очистити вибір
                </Button>
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={2} sx={{ padding: 2, backgroundColor: '#f0f0f0', border: '1px solid #e0e0e0' }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
              {commandLinks.map((link, index) => (
                <Button
                  key={index}
                  variant="outlined"
                  size="small"
                  onClick={() => handleCommandClick(link.command)}
                >
                  {link.text}
                </Button>
              ))}
            </Box>
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Задайте питання або використайте команду"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                margin="normal"
                variant="outlined"
              />
              <Button
                type="submit"
                variant="contained"
                color="secondary" // This will use the purple color
                disabled={loading || !query.trim()}
                startIcon={loading ? <CircularProgress size={20} /> : <ChatIcon />}
                style={{ marginTop: '10px' }}
              >
                {loading ? <CircularProgress size={24} /> : 'Надіслати'}
              </Button>
            </form>
          </Paper>
        </Grid>


     {loading && (
          <Grid item xs={12}>
            <Paper elevation={2} sx={{ padding: 2, backgroundColor: '#f0f0f0', border: '1px solid #e0e0e0' }}>
              <CircularProgress />
              <Typography>Отримуємо відповідь...</Typography>
            </Paper>
          </Grid>
        )}

       {error && (
          <Grid item xs={12}>
            <Paper elevation={2} sx={{ padding: 2, backgroundColor: '#f0f0f0', border: '1px solid #e0e0e0' }}>
              <Typography color="error">{error}</Typography>
            </Paper>
          </Grid>
        )}

      {response && (
          <Grid item xs={12}>
            <Paper
              elevation={2}
              sx={{
                padding: 2,
                backgroundColor: '#f0f0f0',
                border: '1px solid #e0e0e0',
                position: isExpanded ? 'fixed' : 'relative',
                top: isExpanded ? 0 : 'auto',
                left: isExpanded ? 0 : 'auto',
                right: isExpanded ? 0 : 'auto',
                bottom: isExpanded ? 0 : 'auto',
                width: isExpanded ? '100%' : 'auto',
                height: isExpanded ? '100vh' : 'auto',
                zIndex: isExpanded ? 9999 : 'auto',
                overflow: 'auto',
              }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h6">Відповідь</Typography>
                <Box>
          <IconButton onClick={() => handleCopy(response)} size="small">
            <ContentCopyIcon />
          </IconButton>
        </Box>
              </Box>
      <Typography component="div" style={{ whiteSpace: 'pre-wrap' }}>
        {response}
      </Typography>
    </Paper>
  </Grid>
)}





       {chartRequested && chartData && (
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
            <Typography variant="h6" gutterBottom>Візуалізація даних</Typography>

            <Box mb={2}>
              <Typography variant="subtitle1" gutterBottom>Додати фільтр</Typography>
              <FormControl fullWidth margin="normal">
                <InputLabel>Column</InputLabel>
                <Select
                  value={filterColumn}
                  onChange={(e) => setFilterColumn(e.target.value)}
                >
                  {availableColumns.map((column) => (
                    <MenuItem key={column} value={column}>{column}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                label="Filter Value"
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
                margin="normal"
              />
              <Button onClick={addFilter} variant="contained" color="primary">
                Додати фільтр
              </Button>
            </Box>

            {filters.length > 0 && (
              <Box mb={2}>
                <Typography variant="subtitle1" gutterBottom>Активні фільтри</Typography>
                {filters.map((filter, index) => (
                  <Chip
                    key={index}
                    label={`${filter.column}: ${filter.value}`}
                    onDelete={() => removeFilter(index)}
                    style={{ margin: '0 4px 4px 0' }}
                  />
                ))}
              </Box>
            )}

            <Chart
              data={chartData}
              chartType={chartType}
              xAxis={xKey}
              yAxis={yKey}
              availableColumns={availableColumns}
              onChartTypeChange={setChartType}
              onXAxisChange={setXKey}
              onYAxisChange={setYKey}
            />
          </Paper>
        </Grid>
      )}

      {chartSuggestions.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h6">Рекомендовані діаграми</Typography>
          {chartSuggestions.map((suggestion, index) => (
            <Button
              key={index}
              variant="outlined"
              onClick={() => handleChartSuggestionSelect(suggestion)}
              style={{ margin: '5px' }}
            >
              {suggestion.chart_type}: {suggestion.x_axis} vs {suggestion.y_axis}
            </Button>
          ))}
        </Grid>
      )}

      {sortedRelatedDocuments.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>Відповідні документи</Typography>
                  <Box maxHeight={400} overflow="auto">
          {isDocumentsFetching ? (
            <CircularProgress />
          ) : Array.isArray(documents) && documents.length > 0 ? (
            documents.map((doc) => (
              <ListItem key={doc.id} button onClick={() => handleDocumentSelect(doc.id)}>
                <Checkbox
                  edge="start"
                  checked={selectedDocuments.includes(doc.id)}
                  tabIndex={-1}
                  disableRipple
                />
                <ListItemText primary={doc.title} />
              </ListItem>
            ))
          ) : (
            <Typography>Немає доступних документів</Typography>
          )}
        </Box>
        </Grid>
      )}

      <Grid item xs={12}>
          <Paper elevation={2} sx={{ padding: 2, backgroundColor: '#f0f0f0', border: '1px solid #e0e0e0' }}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Історія чату</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {renderChatHistory()}
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveAltIcon />}
                  onClick={handleExport}
                  style={{ marginTop: '16px' }}
                >
                  Експорт історії чату
                </Button>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
      </Grid>

    <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};


export default Chat;