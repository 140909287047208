// src/utils/logger.js

export const logger = {
  error: (...args) => {
    console.error(...args);
  },
  warn: (...args) => {
    console.warn(...args);
  },
  info: (...args) => {
    console.info(...args);
  },
  debug: (...args) => {
    console.debug(...args);
  },
};

export default logger;