import React, { useState, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
  Typography,
} from '@mui/material';
import api from '../services/api';

const CHUNK_SIZE = 1024 * 1024; // 1MB chunks

const UploadNewVersionDialog = ({ open, onClose, documentId, onUploadSuccess }) => {
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setError(null);
  };

  const uploadChunk = async (chunk, chunkIndex, totalChunks) => {
    const formData = new FormData();
    formData.append('file', chunk, file.name);
    formData.append('chunk_index', chunkIndex);
    formData.append('total_chunks', totalChunks);

    try {
      const response = await api.post(`/documents/${documentId}/versions/chunk`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data.temp_folder;
    } catch (error) {
      throw new Error(`Failed to upload chunk ${chunkIndex + 1}/${totalChunks}`);
    }
  };

  const handleErrorMessage = (error) => {
    return error.response?.data?.detail || error.message || 'An unknown error occurred';
  };

  const handleUpload = useCallback(async () => {
    if (!file) {
      setError('Please select a file to upload');
      return;
    }

    setError(null);
    setUploadProgress(0);
    setSuccessMessage('');

    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    let finalTempFolder = null;

    try {
      for (let i = 0; i < totalChunks; i++) {
        const chunk = file.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);
        finalTempFolder = await uploadChunk(chunk, i, totalChunks);
        setUploadProgress(((i + 1) / totalChunks) * 100);
      }

      const requestData = {
        file_name: file.name,
        total_chunks: totalChunks,
        temp_folder: finalTempFolder,
      };

      const response = await api.post(`/documents/${documentId}/versions/complete`, requestData);
      console.log('Complete upload response:', response.data);
      
      setSuccessMessage('Document version uploaded successfully');
      setFile(null);
      setUploadProgress(0);
      setError(null);
      
      if (onUploadSuccess) {
        onUploadSuccess(response.data);
      }
      
      setTimeout(() => {
        onClose();
        setSuccessMessage('');
      }, 2000);
    } catch (err) {
      console.error('Error uploading new version:', err);
      setError(handleErrorMessage(err));
    }
  }, [documentId, file, onClose, onUploadSuccess]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Upload New Version</DialogTitle>
      <DialogContent>
        <input type="file" onChange={handleFileChange} />
        {uploadProgress > 0 && <LinearProgress variant="determinate" value={uploadProgress} />}
        {error && <Typography color="error">{error}</Typography>}
        {successMessage && <Typography color="success">{successMessage}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleUpload} disabled={!file || uploadProgress > 0}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadNewVersionDialog;
