import React, { useState, useEffect } from 'react';
import {
  Typography, List, ListItem, ListItemText, Button, TextField, Box, Tab, Tabs,
  Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem,
  FormControl, InputLabel, CircularProgress, Alert, Snackbar
} from '@mui/material';
import api from '../services/api';
import { logger } from '../utils/logger';

const AdminPanel = () => {
  const [tabValue, setTabValue] = useState(0);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [newUser, setNewUser] = useState({ username: '', email: '', password: '', user_type: 'regular', is_active: true });
  const [newRole, setNewRole] = useState({ name: '', description: '' });
  const [newPermission, setNewPermission] = useState({ name: '', description: '' });
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

   const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const [usersResponse, rolesResponse, permissionsResponse] = await Promise.all([
        api.get('/admin/users'),
        api.get('/admin/roles'),
        api.get('/admin/permissions')
      ]);
      setUsers(usersResponse.data);
      setRoles(rolesResponse.data);
      setPermissions(permissionsResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to load admin data. Please try again later.');
      setSnackbar({ open: true, message: 'Error loading data', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };


  const validateUserData = (userData) => {
    const requiredFields = ['username', 'email', 'password', 'user_type'];
    for (const field of requiredFields) {
      if (!userData[field]) {
        throw new Error(`${field} is required`);
      }
    }
    // Add more specific validations here
  };

  const handleCreateItem = async (type) => {
    try {
      let response;
      switch (type) {
        case 'user':
          validateUserData(newUser);
          response = await api.post('/admin/users', newUser);
          setUsers([...users, response.data]);
          break;
        case 'role':
          response = await api.post('/admin/roles', newRole);
          setRoles([...roles, response.data]);
          break;
        case 'permission':
          response = await api.post('/admin/permissions', newPermission);
          setPermissions([...permissions, response.data]);
          break;
        default:
          throw new Error('Invalid type');
      }
      setSnackbar({ open: true, message: `${type} created successfully`, severity: 'success' });
      handleCloseDialog();
    } catch (error) {
      logger.error(`Error creating ${type}:`, error);  // Use logger instead of console.error
      let errorMessage = `Failed to create ${type}`;
      if (error.response && error.response.data && error.response.data.detail) {
        errorMessage += `: ${error.response.data.detail}`;
      } else if (error.message) {
        errorMessage += `: ${error.message}`;
      }
      setErrorMessage(errorMessage);
      setErrorDialogOpen(true);
    }
  };

  const handleAssignRole = async () => {
    try {
      await api.post('/admin/assign_role', {
        user_id: selectedUser.id,
        role_id: selectedRole,
      });
      fetchData(); // Refresh all data
      setSnackbar({ open: true, message: 'Role assigned successfully', severity: 'success' });
      handleCloseDialog();
    } catch (error) {
      logger.error('Error assigning role:', error);
      setSnackbar({ open: true, message: `Failed to assign role: ${error.message}`, severity: 'error' });
    }
  };

  const handleOpenDialog = (type, user = null) => {
    setDialogType(type);
    setSelectedUser(user);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUser(null);
    setSelectedRole('');
    setNewUser({ username: '', email: '', password: '', user_type: 'regular', is_active: true });
    setNewRole({ name: '', description: '' });
    setNewPermission({ name: '', description: '' });
  };

  const ErrorDialog = ({ open, onClose, error }) => (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <Typography>{error}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>Адміністративна панель</Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} sx={{ mb: 2 }}>
            <Tab label="Користувачі" />
            <Tab label="Ролі" />
            <Tab label="Дозволи" />
          </Tabs>

          {tabValue === 0 && (
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenDialog('createUser')}
                sx={{ mb: 2 }}
              >
                Створити користувача
              </Button>
              <List>
                {users.map((user) => (
                  <ListItem key={user.id} sx={{ border: '1px solid #e0e0e0', mb: 1, borderRadius: 1 }}>
                    <ListItemText
                      primary={user.username}
                      secondary={`Email: ${user.email}, Роль: ${user.role_name || 'Не призначено'}`}
                    />
                    <Button
                      variant="outlined"
                      onClick={() => handleOpenDialog('assignRole', user)}
                      sx={{ ml: 2 }}
                    >
                      Призначити роль
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}

          {tabValue === 1 && (
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenDialog('createRole')}
                sx={{ mb: 2 }}
              >
                Створити роль
              </Button>
              <List>
                {roles.map((role) => (
                  <ListItem key={role.id} sx={{ border: '1px solid #e0e0e0', mb: 1, borderRadius: 1 }}>
                    <ListItemText primary={role.name} secondary={role.description} />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}

          {tabValue === 2 && (
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenDialog('createPermission')}
                sx={{ mb: 2 }}
              >
                Створити дозвіл
              </Button>
              <List>
                {permissions.map((permission) => (
                  <ListItem key={permission.id} sx={{ border: '1px solid #e0e0e0', mb: 1, borderRadius: 1 }}>
                    <ListItemText primary={permission.name} secondary={permission.description} />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </>
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {dialogType === 'assignRole' ? 'Призначити роль' : `Створити ${dialogType === 'createUser' ? 'користувача' : dialogType === 'createRole' ? 'роль' : 'дозвіл'}`}
        </DialogTitle>
        <DialogContent>
          {dialogType === 'createUser' && (
            <>
              <TextField
                label="Ім'я користувача"
                value={newUser.username}
                onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Email"
                type="email"
                value={newUser.email}
                onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Пароль"
                type="password"
                value={newUser.password}
                onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                fullWidth
                margin="normal"
              />
            </>
          )}
          {dialogType === 'createRole' && (
            <>
              <TextField
                label="Назва"
                value={newRole.name}
                onChange={(e) => setNewRole({ ...newRole, name: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Опис"
                value={newRole.description}
                onChange={(e) => setNewRole({ ...newRole, description: e.target.value })}
                fullWidth
                margin="normal"
              />
            </>
          )}
          {dialogType === 'createPermission' && (
            <>
              <TextField
                label="Назва"
                value={newPermission.name}
                onChange={(e) => setNewPermission({ ...newPermission, name: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Опис"
                value={newPermission.description}
                onChange={(e) => setNewPermission({ ...newPermission, description: e.target.value })}
                fullWidth
                margin="normal"
              />
            </>
          )}
          {dialogType === 'assignRole' && (
            <FormControl fullWidth margin="normal">
              <InputLabel>Роль</InputLabel>
              <Select
                value={selectedRole}
                onChange={(e) => setSelectedRole(e.target.value)}
              >
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Скасувати</Button>
          <Button
            onClick={() => dialogType === 'assignRole' ? handleAssignRole() : handleCreateItem(dialogType.slice(6))}
            color="primary"
          >
            {dialogType === 'assignRole' ? 'Призначити' : 'Створити'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AdminPanel;