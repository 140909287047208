import React, { useState, useEffect, useCallback } from 'react';
import {
  List, ListItem, ListItemText, Button, Typography, Box, Paper, Grid, Snackbar,
  CircularProgress, Pagination, Alert
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UploadNewVersionDialog from './UploadNewVersionDialog';
import { useNavigate } from 'react-router-dom';
import FileUpload from './FileUpload';
import { useAuth } from '../contexts/AuthContext';
import SignPopup from './SignPopup';
import SearchBar from './SearchBar';
import api from '../services/api';

function DocumentList() {
  const { user, isAuthenticated, updateUser } = useAuth();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [signPopupOpen, setSignPopupOpen] = useState(false);
  const [selectedDocumentForSigning, setSelectedDocumentForSigning] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchParams, setSearchParams] = useState({});
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const [uploadVersionDialogOpen, setUploadVersionDialogOpen] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  const fetchDocuments = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/documents');
      setDocuments(response.data.documents);
      setError(null);
    } catch (error) {
      console.error('Error fetching documents:', error);
      setError('Failed to fetch documents. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  const handleSearch = useCallback(async (params) => {
    setSearchParams(params);
    setPage(1);
    try {
      setLoading(true);
      const response = await api.get('/search/', {
        params: {
          query: params.query,
          start_date: params.dateRange?.[0]?.toISOString(),
          end_date: params.dateRange?.[1]?.toISOString(),
          file_type: params.fileType,
          tags: params.tags?.join(','),
          page: 1,
          limit: 10
        }
      });
      console.log('Відповідь на пошук:', response.data);
      setDocuments(response.data.results || []);
      setTotalPages(response.data.total_pages || 1);
    } catch (error) {
      console.error('Помилка пошуку документів:', error);
      setSnackbar({ open: true, message: 'Помилка пошуку документів. Спробуйте ще раз.', severity: 'error' });
    } finally {
      setLoading(false);
    }
  }, []);

  const handleViewDetails = useCallback((id) => {
    navigate(`/document/${id}`);
  }, [navigate]);

  const handleUploadNewVersion = useCallback((id) => {
    setSelectedDocumentId(id);
    setUploadVersionDialogOpen(true);
  }, []);

  const handleVersionUploadSuccess = useCallback((newVersion) => {
    setDocuments(prevDocs => prevDocs.map(doc =>
      doc.id === newVersion.document_id ? {
        ...doc,
        current_version: newVersion.version_number,
        is_signed: false, // Reset signing status
        file_type: newVersion.file_type // Update file type
      } : doc
    ));
    setSnackbar({ open: true, message: 'Нову версію успішно завантажено', severity: 'success' });
    setUploadVersionDialogOpen(false);
  }, []);

  const handleDelete = useCallback(async (id) => {
    try {
      await api.delete(`/documents/${id}`);
      setDocuments(prevDocs => prevDocs.filter(doc => doc.id !== id));
      setSnackbar({ open: true, message: 'Документ успішно видалено', severity: 'success' });
    } catch (error) {
      console.error('Помилка видалення документа:', error);
      setSnackbar({ open: true, message: 'Помилка видалення документа.', severity: 'error' });
    }
  }, []);

  const handleFileUploaded = useCallback((newDoc) => {
    setDocuments(prevDocs => [newDoc, ...prevDocs]);
  }, []);

  const handleSignDocument = useCallback((doc) => {
    setSelectedDocumentForSigning(doc);
    setSignPopupOpen(true);
  }, []);

  const handleSignPopupClose = useCallback(() => {
    setSignPopupOpen(false);
    setSelectedDocumentForSigning(null);
  }, []);

  const handleDocumentSigned = useCallback(async (signedDocumentId) => {
    console.log('Документ підписано:', signedDocumentId);
    fetchDocuments();
  }, [fetchDocuments]);

  const handlePageChange = useCallback((event, value) => {
    setPage(value);
  }, []);

  if (!isAuthenticated) {
    return <Typography>Будь ласка, залогіньтесь, щоб переглянути свої документи.</Typography>;
  }

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box sx={{ flexGrow: 1, m: 3 }}>
      {/* <Typography variant="subtitle1" gutterBottom>Logged in as: {user?.username} (Role: {user?.role})</Typography> */}
      <Typography variant="h4" gutterBottom>Ваші документи</Typography>
      {error && <Alert severity="error">{error}</Alert>}

      <SearchBar onSearch={handleSearch} />
      <FileUpload onFileUploaded={handleFileUploaded} />

      {documents.length > 0 ? (
        <List sx={{ mt: 2 }}>
          {documents.map((doc) => (
            <Paper key={doc.id || doc.document_id} elevation={2} sx={{ mb: 2, p: 2 }}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={doc.title || doc.metadata?.title}
                  secondary={
                    <React.Fragment>
                      <Typography component="span" variant="body2" color="text.primary">
                        {doc.description || doc.metadata?.description}
                      </Typography>
                      <Typography variant="caption" display="block">
                        Дата завантаження: {new Date(doc.upload_date || doc.metadata?.upload_date).toLocaleString()}
                      </Typography>
                      {doc.score && (
                        <Typography variant="caption" display="block">
                          Оцінка: {doc.score.toFixed(2)}
                        </Typography>
                      )}
                    </React.Fragment>
                  }
                />
                <Grid container spacing={1} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      variant="outlined"
                      startIcon={<VisibilityIcon />}
                      onClick={() => handleViewDetails(doc.id || doc.document_id)}
                    >
                      Переглянути
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      startIcon={<CloudUploadIcon />}
                      onClick={() => handleUploadNewVersion(doc.id || doc.document_id)}
                    >
                      Нова версія
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      startIcon={<CreateIcon />}
                      onClick={() => handleSignDocument(doc)}
                      disabled={doc.is_signed}
                    >
                      {doc.is_signed ? 'Підписано' : 'Підписати'}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="error"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleDelete(doc.id || doc.document_id)}
                    >
                      Видалити
                    </Button>
                  </Grid>
                </Grid>
              </ListItem>
            </Paper>
          ))}
        </List>
      ) : (
        <Typography sx={{ mt: 2 }}>Документи не знайдено.</Typography>
      )}

      {totalPages > 1 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, value) => setPage(value)}
            color="primary"
          />
        </Box>
      )}

      <SignPopup
        open={signPopupOpen}
        onClose={handleSignPopupClose}
        documentId={selectedDocumentForSigning?.id || selectedDocumentForSigning?.document_id}
        onDocumentSigned={handleDocumentSigned}
      />
    <UploadNewVersionDialog
        open={uploadVersionDialogOpen}
        onClose={() => setUploadVersionDialogOpen(false)}
        documentId={selectedDocumentId}
        onUploadSuccess={handleVersionUploadSuccess}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default DocumentList;