import React, { useState, useCallback } from 'react';
import { TextField, IconButton, InputAdornment, Box, Button, Collapse, Select, MenuItem, Chip, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState('');
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fileType, setFileType] = useState('');
  const [tags, setTags] = useState([]);

  const handleSearch = useCallback(() => {
    onSearch({
      query,
      startDate,
      endDate,
      fileType,
      tags
    });
  }, [query, startDate, endDate, fileType, tags, onSearch]);

  const handleAddTag = useCallback((event) => {
    if (event.key === 'Enter' && event.target.value) {
      setTags([...tags, event.target.value]);
      event.target.value = '';
    }
  }, [tags]);

  const handleRemoveTag = useCallback((tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  }, [tags]);

  return (
    <Box>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Пошук документів..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
      />
      <Button
        onClick={() => setShowAdvanced(!showAdvanced)}
        endIcon={<ExpandMoreIcon />}
      >
        Розширений пошук
      </Button>
      <Collapse in={showAdvanced}>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Від"
              value={startDate}
              onChange={setStartDate}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="До"
              value={endDate}
              onChange={setEndDate}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              value={fileType}
              onChange={(e) => setFileType(e.target.value)}
              displayEmpty
              fullWidth
            >
              <MenuItem value="">Всі типи файлів</MenuItem>
              <MenuItem value="pdf">PDF</MenuItem>
              <MenuItem value="doc">DOC</MenuItem>
              <MenuItem value="xls">XLS</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              placeholder="Додати тег та натиснути Enter"
              onKeyPress={handleAddTag}
            />
          </Grid>
          <Grid item xs={12}>
            {tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => handleRemoveTag(tag)}
                style={{ margin: '4px' }}
              />
            ))}
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
};

export default SearchBar;