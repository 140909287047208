import React, { useState, useCallback } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  CircularProgress,
  Tooltip,
  Alert,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from '@mui/material';
import { saveAs } from 'file-saver';
import { Download, FileDown, AlertTriangle } from 'lucide-react';
import { errorMonitoring } from '../services/errorMonitoring';
import api from '../services/api';

const DocumentDownloadHandler = ({ documentId, documentTitle, disabled }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSignatureWarning, setShowSignatureWarning] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });

const verifySignature = useCallback(async () => {
  try {
    const response = await api.get(`/documents/${documentId}/signature-info`);
    console.log('Signature verification response:', response.data);

    if (!response.data.is_signed) {
      setShowSignatureWarning(true);
      return false;
    }

    // Verify all required fields are present
    const requiredFields = {
      signer_name: 'імʼя підписанта',
      signer_tax_number: 'податковий номер',
      signature_date: 'дата підписання',
      signature_method: 'метод підписання'
    };

    const missingFields = Object.entries(requiredFields)
      .filter(([key]) => !response.data[key])
      .map(([_, label]) => label);

    if (missingFields.length > 0) {
      throw new Error(`Відсутні обов'язкові поля підпису: ${missingFields.join(', ')}`);
    }

    return true;
  } catch (error) {
    console.error('Signature verification error:', error);

    if (error.response?.status === 404) {
      throw new Error('Підписаний документ не знайдено');
    }

    if (error.response?.status === 403) {
      throw new Error('Немає прав для перегляду інформації про підпис');
    }

    throw new Error(error.response?.data?.detail || error.message);
  }
}, [documentId]);

const handleDownload = useCallback(async (type) => {
  setIsDownloading(true);
  setError(null);

  try {
    if (type === 'signed') {
      const isValid = await verifySignature();
      if (!isValid) {
        return;
      }
    }

    const endpoint = type === 'signed'
      ? `/documents/${documentId}/download-signed`
      : `/documents/${documentId}/download-original`;

    const response = await api.get(endpoint, {
      responseType: 'blob',
      timeout: 30000,
    });

    // Parse filename from Content-Disposition header
    const contentDisposition = response.headers['content-disposition'];
    let filename = documentTitle;

    if (contentDisposition) {
      const matches = contentDisposition.match(/filename\*=UTF-8''([^;]+)/i);
      if (matches && matches[1]) {
        filename = decodeURIComponent(matches[1]);
      }
    }

    // Create download link
    const blob = new Blob([response.data], {
      type: response.headers['content-type'] || 'application/octet-stream'
    });

    // Use native browser download with UTF-8 support
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;

    // Required for Firefox
    link.style.display = 'none';
    document.body.appendChild(link);

    link.click();

    // Cleanup
    setTimeout(() => {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, 100);

  } catch (error) {
    console.error('Download error:', error);
    const errorMessage = error.response?.data?.detail || error.message;
    setError(errorMessage);
    setSnackbar({
      open: true,
      message: `Error downloading document: ${errorMessage}`,
      severity: 'error'
    });
  } finally {
    setIsDownloading(false);
    setAnchorEl(null);
  }
}, [documentId, documentTitle, verifySignature]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Your existing return statement stays the same
  return (
    <>
      <Tooltip title={error || "Завантажити документ"}>
        <IconButton
          onClick={handleMenuClick}
          disabled={disabled || isDownloading}
          color={error ? "error" : "default"}
        >
          {isDownloading ? (
            <CircularProgress size={24} />
          ) : (
            <Download size={24} />
          )}
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => handleDownload('original')}
          disabled={isDownloading}
        >
          <FileDown size={18} style={{ marginRight: 8 }} />
          Оригінальний документ
        </MenuItem>
        <MenuItem
          onClick={() => handleDownload('signed')}
          disabled={isDownloading}
        >
          <FileDown size={18} style={{ marginRight: 8 }} />
          Підписаний документ (p7s)
        </MenuItem>
      </Menu>

      <Dialog
        open={showSignatureWarning}
        onClose={() => setShowSignatureWarning(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <AlertTriangle color="warning" />
          Документ не підписано
        </DialogTitle>
        <DialogContent>
          <Typography>
            Цей документ ще не було підписано. Спочатку підпишіть документ, щоб мати можливість завантажити підписану версію.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowSignatureWarning(false)} color="primary">
            Зрозуміло
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          elevation={6}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default DocumentDownloadHandler;